import * as React from 'react';

import { isDark } from '../theme';

import { Map, Popup, Marker, MapRef } from 'react-map-gl';
import { useState, useRef } from 'react';

import { useQuery, gql } from "@apollo/client";
import { isAuthError, getAuthContext } from "../auth";
import { useNavigate } from "react-router-dom";
import TableViewIcon from '@mui/icons-material/TableView';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

import WellSummary from '../well_summary';
import { AlertsBrief } from '../alerts_brief';
import Badge from '@mui/material/Badge';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

// We getting alerts just so we could display alets button it is  silly to query them but.... good for now

export const GET_WELLS = gql`
  query {

    wells {
        name,
        wellId,
        lat, 
        lon
    },

    alerts {
        well{
          wellId
        }
    }

  }
`;

interface IPopupInfo {
  lat: number,
  lon: number,
  message: string
}



function MapView() {
  //const {current: map} = useMap();
  const mapRef = useRef<any>();

  const { loading, error, data } = useQuery(GET_WELLS, getAuthContext());

  const [popupInfo, setPopupInfo] = useState<IPopupInfo | undefined>(undefined);
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  function isProblematicWell(well_id: any) {
    if (data) {
      for (const alert of data.alerts) {
        const delta = Number(well_id) - Number(alert.well.wellId);
        if (delta == 0) {
          return true;
        }
      }
    }
    return false;
  }

  function goToWell(param_well: any, lat: any, lon: any) {
    console.log("go to well.." + param_well + ' lat' + lat + ' lon' + lon);
    console.log("map: ", mapRef.current);
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [lon, lat],
        zoom: 17,
        essential: true
      });
    }

  }

  if (error) {
    if (isAuthError(error)) {
      navigate("/login");
    }
  }

  return <>

    <Map
      ref={mapRef}
      mapLib={import('mapbox-gl')}
      initialViewState={{
        longitude: -103.89,
        latitude: 32.85,
        zoom: 14
      }}
      style={{ height: '100%', width: '100%' }}
      mapStyle={isDark() ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/outdoors-v12'}
      mapboxAccessToken='pk.eyJ1IjoiZGVueXMtZWxlY3RpbyIsImEiOiJjbHhtMzZ4d3QwM2gxMmpvZ25xOHo1b2huIn0.vUSaAzbKnofX9ngOm7ngLw'
      interactiveLayerIds={['wells-layer']}
    >

      {
        data ?

          data.wells.map((well: any) =>
            <Marker
              key={"well_" + well.wellId}
              longitude={well.lon}
              latitude={well.lat}
              anchor="bottom"
              onClick={(e: any) => {
                // If we let the click event propagates to the map, it will immediately close the popup
                // with `closeOnClick: true`
                e.originalEvent.stopPropagation();
                console.log(e.target._latlng)
                setPopupInfo(
                  {
                    lat: well.lat,
                    lon: well.lon,
                    message: "Oh shit went completely south here"
                  }
                );
                console.log("EVENT:" + e);
              }}
            >
              <img className="left-1/2 transform -translate-x-1/2 cursor-pointer" src={isProblematicWell(well.wellId) ? "static/round-well-dark-red.png" : "static/round-well-dark.png"} width="42px" height="42px" />
              <div className="left-1/2 transform -translate-x-1/2 shadow-sm justify-center text-gray-900 dark:text-gray-500 cursor-pointer">{well.name}</div>

            </Marker>
          )

          : <></>

      }

      {popupInfo ?
        <Popup
          anchor="top"
          longitude={Number(popupInfo.lon)}
          latitude={Number(popupInfo.lat)}
          onClose={() => setPopupInfo(undefined)}
        >
          <WellSummary />

        </Popup>

        : <></>}


    </Map>

    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 +translate-y-1/2  justify-center rounded-md bg-gray-700 dark:bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white dark:text-gray-300 shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100 shadow-mn dark:shadow-slate-950">
      <button onClick={toggleDrawer(true)}>

        {data && (data.alerts.length > 0) ?
          <Badge badgeContent={data.alerts.length} color="secondary">
            <NotificationImportantIcon color="action" />
          </Badge>
          :
          <></>
        }

      </button>
    </div>


      
    <SwipeableDrawer
      anchor="bottom"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={true}
      swipeAreaWidth={0}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}

    >

      <div>
        <div>

        </div>

        
        <Box sx={
          {
            width: 50,
            height: 15,
            backgroundColor: grey[700],
            borderRadius: 3,
            position: 'absolute',
            top: 8,
            left: 'calc(50% - 15px)',
          }}>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            backgroundColor: grey[800],
          }}
        >






        </Box>

        <AlertsBrief onClick={goToWell} />

      </div>




    </SwipeableDrawer>



  </>
}

export default MapView;