import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn, getAuthContext, isAuthError } from './auth';
import { gql, useQuery } from "@apollo/client";

export type ProtectedRouteProps = {
  outlet: JSX.Element;
};

const GET_USERNAME_GQL = gql`
query{
  whoami{
   username
 }
}
`;

export default function PrivateRoute({ outlet }: ProtectedRouteProps) {
  const currentLocation = useLocation();
  const { loading, error, data } = useQuery(GET_USERNAME_GQL, getAuthContext());

  if (error) {
    isAuthError(error);
  }

  if (isLoggedIn()) {
    return outlet;
  } else {
    return <Navigate to='/login' />;
  }
};
