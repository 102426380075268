import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function WellSummary() {
  return (
    <Card sx={{ maxWidth: 345, background: "rgb(15, 23, 42)" }}>
      
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Well XXXX
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Avg Per Day Production:	1,507 Mcf  |  $ 5,049.64 ATW dollars
        Total Production Days:	3,024
        Liquid Waste:	1,497,571 Gallons (35,656 Bbls)
        Site Inspections:	13 inspections
        Violations Cited:	1 violations
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">More Details...</Button>
      </CardActions>
    </Card>
  );
}