import React from 'react';
import { Outlet } from "react-router-dom";
import Sidebar from '../sidebar'

function Root() {
  const [drawerOpen, setDraweOpen] = React.useState(false);

  const toggleSidebar = (newOpen: boolean) => () => {
    setDraweOpen(newOpen);
  };

  return (
    
<div style={{ display: 'flex',  height: '100%', minHeight: '400px' }}>
    <div id="detail" style={{ height: '100vh', flexGrow: 1, minHeight: '400px' , background:"rgb(15, 23, 42)" }}>
        <Outlet />
    </div>
    <Sidebar/>
    </div>
  )
}

export default Root;
