import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from "./routes/root";
import ErrorPage from "./routes/error";
import Alerts from "./routes/alerts";
import MapView from "./routes/map_view";
import Login from "./routes/login";
import Logout from "./routes/logout";

import PrivateRoute from "./private_route";
import is_dev from './utils'


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
  //uri:  'graphql/', //is_dev() ? 'http://' + window.location.hostname + ':8000/graphql/' : 'graphql/', // Replace with your GraphQL API endpoint
  //uri:  'http://' + window.location.hostname + ':8000/graphql/',
  uri:  'graphql/',
  cache: new InMemoryCache()
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "alerts/",
        element: <PrivateRoute outlet={<Alerts />}/>,
      },
      {
        path: "map/",
        element: <PrivateRoute outlet={<MapView />}/>,
      },
    ],
  },
  {
    path: "login/",
    element: <Login />,
  },

  {
    path: "logout/",
    element: <Logout />,
  },

]
);

root.render(
  <React.StrictMode>
    
    <ApolloProvider client={client}>
      
    <ThemeProvider theme={darkTheme}>
        <RouterProvider router={router} />
    </ThemeProvider>

    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
