import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
<div id="error-page" className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-slate-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-300">
          ERROR #404: …Oops, it looks like you’ve got lost
        </h2>
      </div>

    </div>

  );
}