import { useState } from "react";
import {storeToken} from "../auth"; 
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";


const LOGIN_GQL = gql`
 mutation doLogin($user: String!, $pass: String!){
   tokenAuth(username:$user,password:$pass){
     token,
     refreshToken,
     refreshExpiresIn,
     payload
   }
 }
`;

const PROFILE_LOAD_GQL = gql`
query{
 userProfile{
   darkTheme,
   avatar
 }
}
`;


export default function LoginPage() {
    const navigate = useNavigate();
    //const profile_store = useProfileStore();

      const [try_login, { data, loading, error }] = useMutation(LOGIN_GQL, 
        {
            onError: (data) => {
                console.log("Error occurred, while running GQL query");
            }
        }
    );

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    function handleSubmit(event:any) {
        event.preventDefault();
        
        try_login(
            {
                variables:{
                    user:username, 
                    pass:password
                }
            }
        )
    }
    
    if (data){
            console.log("Received auth token:" + data.tokenAuth.token);
            storeToken(data.tokenAuth.token);
            navigate("/Map");
    }

    if (error){
        console.log("error:" + error.message);
        storeToken("");
    }


    if (loading){
        console.log("loading...");
    }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-slate-900">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="static/Electio_v2.png"
            alt="Electio"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-300">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
                Username
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="username"
                  autoComplete="username"
                  onInput={ (e:any)=> setUsername(e.target.value) }
                  required
                  className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 dark:bg-slate-800"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
                  Password
                </label>
                {/*
                <div className="text-sm">
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </a>
                </div>
                */}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  onInput={ (e:any)=> setPassword(e.target.value) }
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="px-3 block w-full rounded-md border-1 py-1.5 text-gray-900 dark:text-gray-100 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 dark:bg-slate-800 dark:border-indigo-950"
                />
              </div>
            </div>
            
            <div>
              
            {error ? // TODO distinguish bettween server communication errors and auth errors
              <div className="text-center text-xs font-bold tracking-tight text-red-500">
                Error: Invalid username or password
              </div>
            : <></>}
            </div>

            
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-gray-700 dark:bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white dark:text-gray-300 shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100 shadow-mn dark:shadow-slate-950"
              >
                Sign in
              </button>
            </div>
          </form>
          </div>

    </div>
  );
}