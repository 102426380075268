
export function storeToken(token: string) {
    localStorage.setItem("auth_token", token);
    storeLoggedIn();
}



//export function setAssumeLoggedIn()
//{
//    localStorage.setItem("assume_logged_in", 1);
//}

export function isLoggedIn(): Boolean {
    var result_str = localStorage.getItem("logged_in");
    if (result_str) {
        var result = Number(result_str) == 1;
        console.log("is logged in: " + result)
        return result;
    }
    console.log("logged in? - fuck no")
    return false;
}

export function storeLoggedIn() {
    localStorage.setItem("logged_in", "1");
}

export function invalidateLogin() {
    localStorage.setItem("logged_in", "0");
}

export function getToken(): string {
    //return development;
    var result = localStorage.getItem("auth_token");
    if (result) {
        return result;
    }
    return "";
}

export function getAuthContext(): any {
    const token_str = getToken();
    var token = { context: { headers: { authorization: `Bearer ${token_str}` } } };
    console.log('token = ' + token + ' token_str:' + token_str);
    return token;
}

export function isAuthError(error: any) {
    try {
        if (error.message.includes("Authentication Failure") > 0) {
            console.log("welp you didn't login")
            invalidateLogin();
            return true;
        }

        if (error.message.includes("Signature has expired") > 0) {
            console.log("welp you didn't login (expired)")
            invalidateLogin();
            return true;
        }



    } catch (e: any) {
        console.log("WTF:" + error.message + " e:" + e);
    }
    return false;
}