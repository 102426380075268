import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { gql, useQuery } from "@apollo/client";
import { isAuthError, getAuthContext } from "./auth";
import NotificationsIcon from '@mui/icons-material/Notifications';

//import Avatar from './avatar';

const AVATAR_GQL = gql`
query{
 userProfile{
   avatar
 }
 
 whoami{
  username,
  firstName,
  lastName,
 }
}
`;

export default function Sidebar() {
  const { loading, error, data } = useQuery(AVATAR_GQL,  getAuthContext() );
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const go_navigate = (new_page: string) => () => {
    navigate(new_page);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      

      <List>
      <ListItem>
      {
        data ?
          <>
          <Avatar src={data.userProfile.avatar} /> 
          <ListItemText sx={{ m: 2 }} primary={data.whoami.firstName  + " " +  data.whoami.lastName} />
          </>

        :
          <Avatar src="/broken-image.jpg" />

      }
      </ListItem>

      <ListItem>
            <ListItemButton  onClick={go_navigate("/map")}>
                <ListItemIcon>
                    <MapIcon/> 
                </ListItemIcon>
                <ListItemText primary={"Map"}/>
            </ListItemButton>
        </ListItem>

        <ListItem>
            <ListItemButton onClick={go_navigate("/alerts")}>
                <ListItemIcon>
                    <NotificationsIcon/> 
                </ListItemIcon>
                <ListItemText primary={"Alerts"}/>
            </ListItemButton>
        </ListItem>
      </List>
      
    </Box>
  );

  return (
    <>
      

      <div className="absolute top-0 transform +translate-x-100   +translate-y-1/2  justify-center rounded-md bg-gray-700 dark:bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white dark:text-gray-300 shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100 shadow-mn dark:shadow-slate-950">
        <button onClick={toggleDrawer(true)}> <MenuIcon sx={{color:"#cbd5e1"}} />  </button>
      </div>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}