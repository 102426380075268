import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useQuery, useLazyQuery, gql, ApolloClient, InMemoryCache } from "@apollo/client";
import { isAuthError, getAuthContext } from "./auth";
import Alert from '@mui/material/Alert';

export const GET_ALERTS = gql`
query{
  alerts{
    id,
    desctription,
    severity,
    state,
    well{
      name,
      lat, 
      lon
    }
  }
}
`;



export function AlertsBrief({ onClick }: { onClick? : (well:string, lat:Number, lon:Number)=>void }): JSX.Element {
  const { loading, error, data } = useQuery(GET_ALERTS,  getAuthContext() );

  function clicked(e:any){
    console.log("clicked");
    //if (onClick) {onClick("yolo"); };
}

  return <>
  {data ?
  
<TableContainer className="max-h-72">
      <Table sx={{ minWidth: 150 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Severity</TableCell>
            <TableCell align="right">State</TableCell>
            <TableCell align="right">Description</TableCell>
            <TableCell align="right">Well</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {data.alerts.map( (alert:any) => (
            <TableRow key={alert.id}>
              <TableCell  component="th" scope="row">
                
                {alert.severity > 0 ? 
                    <Alert severity="error" variant="outlined">high</Alert> : 
                    <Alert severity="warning" variant="outlined">low</Alert>
                }
              </TableCell>
              
              <TableCell align="right">
                {alert.state == 0 ? "new" : "Acknowledged"}
              </TableCell>
              <TableCell align="right">{alert.desctription}</TableCell>
              <TableCell align="right"> <button onClick={() => {if (onClick) {onClick(alert.well.name, alert.well.lat, alert.well.lon); }; }}>{alert.well.name}</button> </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    :
    <></>
        }
    </>
}
