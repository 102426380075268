import { useEffect } from "react";
import { storeToken } from "../auth";
import { useNavigate } from "react-router-dom";


export default function LogoutPage() {

  const navigate = useNavigate();
  useEffect(() => {
    console.log("clearing token");
    storeToken("");
  });

  function goToLogin(event: any) {
    navigate('/login');
  }

  return (
    <div className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8 dark:bg-slate-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src="static/AoE_Electio_v2.png"
          alt="Electio"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-300">
          You have successfully logged out.
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

        <div>
          <button
            className="flex w-full justify-center rounded-md bg-gray-700 dark:bg-slate-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white dark:text-gray-300 shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100 shadow-mn dark:shadow-slate-950"
            onClick={goToLogin}
          >
            Navigate back to login page
          </button>
        </div>

      </div>

    </div>
  );
}